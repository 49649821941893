<template>
  <div class="kj">
    <div class="swipe">
      <img src="../assets/mill/1.png" alt="" />
    </div>
    <div class="notice">
      <van-notice-bar
        color="#333333"
        wrapable
        background="rgba(248,222,77,0.3)"
        mode="link"
      >
        <template #left-icon>
          <img src="../assets/home/list.png" alt="" />
        </template>
        <template #default
          >Purchased Mining Machines Permanently Belong to Users, Ensuring
          Long-Term Mining Profits</template
        >
        <template #right-icon> </template>
      </van-notice-bar>
    </div>
    <div
      class="kj-item"
      v-for="(item, index) in homeList"
      :key="index"
      @click="toKjDetails(item)"
    >
      <div class="left">
        <p
          style="margin-top: 0;font-size: 14px;font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi-B;"
        >
          {{ item.name }}
        </p>
        <p
          style="color: #999999;margin-top: 10px;font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;"
        >
          Power Consumption per Unit Hashrate
        </p>
        <p class="p3" style="margin-top: 10px;">
          <span
            style="font-size: 20px;font-family: DINPro-Bold, DINPro-Bold;"
            >{{ item.minbuyCalculation }}</span
          >
          <!-- <span class="tag">支持矿池打币</span> -->
        </p>
      </div>
      <div class="right">
        <div class="text">{{ item.validity }}</div>
        <img src="../assets/detail/kj.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "kj",
  data() {
    return { bannerList: [], homeList: [], mobile: "" }
  },
  created() {
    this.getHomeList()
    // this.mobile = localStorage.getItem("mobile")
  },
  methods: {
    toKjDetails(url) {
      this.$router.push({
        path: "/kjDetails",
        name: "kjDetails",
        query: {
          id: url.id,
        },
      })
    },
    getHomeList() {
      this.$request
        .get("/kjInfo/list")
        .then(
          (res) => {
            if (res.code == 200) {
              this.homeList = res.data.list
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {},
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kj {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  height: auto;
  overflow: auto;
  padding: 20px 20px 60px;
  .swipe {
    width: 100%;
    height: 100px;
    background: #d8d8d8;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .notice {
    text-align: left;
    width: 100%;
    padding: 13px 0 18px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-size: 12px;
    color: #333333;
    .van-notice-bar {
      font-size: 12px;
      padding: 0;
      height: 57px;
      font-weight: bolder;
      line-height: 16px;
      border-radius: 8px;
      padding-right: 5px;
    }
    img {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px;
    }
  }
  .kj-item {
    width: 100%;
    height: 110px;
    background: #f9f9f9;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 12px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 16px;
    p {
      padding: 0;
      margin: 0;
    }
    .left {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: 700;
      font-size: 12px;
      color: #333333;
      font-style: normal;
      .p3 {
        display: flex;
        align-items: center;
      }
      .tag {
        width: 80px;
        height: 18px;
        border-radius: 1px;
        border: 1px solid #ff3500;
        font-weight: 400;
        font-size: 12px;
        color: #ff3500;
        margin-left: 6px;
      }
    }
    .right {
      position: relative;
      height: 100%;
      .text {
        position: absolute;
        right: -12px;
        top: 0;
        height: 22px;
        background: #f8de4d;
        border-radius: 0px 8px 0px 0px;
        font-weight: 600;
        font-size: 10px;
        color: #4a4a4a;
        text-align: center;
        line-height: 22px;
        width: max-content;
        padding: 0 4px;
      }
    }
    img {
      margin-top: 40px;
      width: 50px;
      height: 50px;
    }
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
